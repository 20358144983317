import * as examOrderServices from './examOrder.service';
import * as examServices from './exam.service';
import * as examOrderHistoryServices from './examOrderHistory.service';
import * as examOrderDashboardServices from './examOrderDashboard.service';
import * as courseEnrollmentServices from './courseEnrollment.service';
import * as contentListServices from './contentList.service';
import * as examOrderItemServices from './examOrderItem.service';

export {
  examServices,
  examOrderServices,
  examOrderHistoryServices,
  examOrderDashboardServices,
  courseEnrollmentServices,
  contentListServices,
  examOrderItemServices
};
