import { UserRole } from 'constants/app.constant';
import { PagingOptions } from '../models/exam.model';

export const ALCRoutePath = {
  rootPath: '/alc',
  examOrder: {
    home: '/alc/order-hesi-exams',
    programTypes: '/alc/order-hesi-exams/program-types',
    examTypes: '/alc/order-hesi-exams/exam-types',
    examCatalog: '/alc/order-hesi-exams/exam-catalog',
    examOrderCartReview: '/alc/order-hesi-exams/exam-order-cart/review',
    examOrderCartPayment: '/alc/order-hesi-exams/exam-order-cart/payment',
    examOrderCartConfirm: '/alc/order-hesi-exams/exam-order-cart/confirm',
    examOrderProcessPayment: '/alc/order-hesi-exams/exam-order-process/:tspHesiExamPymtOrderId/payment',
    examOrderProcessCancel: '/alc/order-hesi-exams/exam-order-process/:tspHesiExamPymtOrderId/cancel'
  },
  examOrderHistory: {
    examOrderHistory: '/alc/exam-order-history',
    examOrderHistoryDetails: '/alc/exam-order-history/exam-order-detail/:tspHesiExamPymtOrderId',
    error: {
      pageNotFound: '/alc/exam-order-history/page-not-found',
      notAuthorized: '/alc/exam-order-history/not-authorized'
    }
  },
  examOrderDashboard: {
    examOrderDashboard: '/alc/exam-order-dashboard',
    examOrderDetails: '/alc/exam-order-dashboard/exam-order-detail/:tspHesiExamPymtOrderId',
    error: {
      pageNotFound: '/alc/exam-order-dashboard/page-not-found',
      notAuthorized: '/alc/exam-order-dashboard/not-authorized'
    }
  },
  examGroup: {
    studentRegistration: '/alc/exam-group/student-registration'
  },
  error: {
    pageNotFound: '/alc/page-not-found',
    notAuthorized: '/alc/not-authorized'
  }
};

export const DEFAULT_HESI_ASSESS_ROLES = [
  UserRole.HesiSupportStakeholder
];

export const ALCConstants = {
  redux: {
    HESI_STATE: 'hesiState',
    EXAM_ORDER_STATE: 'examOrderState'
  }
};
export const defaultExamPagingOptions: PagingOptions = {
  currentPage: 1,
  resultsPerPage: 25,
  sortBy: 'name',
  sortOrder: 'asc',
  query: '',
  selectedProgramTypes: [],
  selectedAssessmentTypes: []
};
export const ALCAnalytics = {
  pageType: {
    orderHESIExams: 'oe',
    examTypes: 'oe-et',
    examCatalog: 'oe-ec',
    orderCartReview: 'oe-oc',
    orderCartPayment: 'oe-oc',
    orderCartConfirm: 'oe-oc',
    orderProcessPayment: 'oe-op-p',
    orderProcessCancel: 'oe-op-c',
    notAuthorized: 'oe-na',
    pageNotFound: 'oe-nf',
    examOrderHistory: 'oe-oh',
    examOrderHistoryDetails: 'oe-oh-d',
    examOrderDashboard: 'oe-od',
    examOrderDetails: 'oe-od-ed',
    examGroupStudentRegistration: 'oe-eg-sr'
  }
};

export const HESI_FILTER_TYPES = {
  PROGRAM_TYPE: 'programType',
  EXAM_TYPE: 'examType'
};
