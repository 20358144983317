import { clone, isNil, omitBy } from 'lodash';
import { tspServiceApi } from 'helpers/RestClient.helper';
import { getPayloadAttachment } from 'services/invoice-credit-rebill/invoiceCreditRebill.service';
import { Attachment } from 'models/credit-request.models';
import { ExamOrderItemActivityModel, ExamOrderItemModel, ExamOrderItemStatusModel } from '../models/examOrder.model';
import { EXAM_ORDER_ADD_LICENSE_REASONS, ExamOrderItemEvents } from '../constants/exam-order.constant';
import { ExamOrderItemAddLicenseEditorModel } from '../components/exam-section/exam-order-item-add-licenses/ExamOrderItemAddLicensesEditor.component';

export const getExamOrderItemsById = (tspHesiExamPymtOrderId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${tspHesiExamPymtOrderId}/examOrderItems`) as Promise<{
    examOrderId: string;
    tspHesiExamPymtOrderId: number;
    examOrderItems: ExamOrderItemModel[];
  }>;

type GetExamOrderItemCommentsResponse = { comments: ExamOrderItemActivityModel[]; totalCount: number };
export const getExamOrderItemComments = (tspHesiExamPymtOrderItemId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrderItems/${tspHesiExamPymtOrderItemId}/comments`) as Promise<GetExamOrderItemCommentsResponse>;

type UpdateExamOrderItemResponse = {
  comment?: string;
  attachments?: Attachment[],
  examOrderItemId: string;
  examOrderItemStatus: ExamOrderItemStatusModel;
  examOrderItemExpirationDate: string;
  productId: string;
  productName: string;
  programId: number;
  programName: string;
  organizationId: number;
  organizationName: string;
};

type UpdateExamOrderItemRequest = {
  type: string;
  tspHesiExamPymtOrderItemId: number;
  associatedFaculties?: string[];
  examOrderItemStatusId?: number;
  expirationDate?: string;
  comment?: string;
  attachments?: Attachment[];
};
export const canExamOrderItemBeClosed = (tspHesiExamPymtOrderItemId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrderItems/${tspHesiExamPymtOrderItemId}/canBeClosed`) as Promise<{ canBeClosed: boolean; message: string | null }>;

export const updateExamOrderItem = (data: UpdateExamOrderItemRequest) => {
  const payload = clone(omitBy(data, isNil));
  return tspServiceApi.put(`/hesi/alc/admin/examOrderItems/${data.tspHesiExamPymtOrderItemId}`, { data: payload }) as Promise<UpdateExamOrderItemResponse>;
};

type UpdateExamOrderItemPricePerStudentRequest = {
  productPrice: string;
  comment: string;
  attachments: Attachment[];
  tspHesiExamPymtOrderItemId: number;
  type: string;
}
export const updateExamOrderItemPricePerStudent = async (data: UpdateExamOrderItemPricePerStudentRequest) => {
  const payload = clone(omitBy(data, isNil));
  const attachments = data.attachments || [];
  const allPromise = attachments.map((attachment, index) => getPayloadAttachment(payload, attachment, index));
  await Promise.all(allPromise);
  return updateExamOrderItem(payload);
};

export const addExamOrderItemComment = async (tspHesiExamPymtOrderItemId: number, comment: string, attachmentsList) => {
  const data = {
    tspHesiExamPymtOrderItemId,
    comment,
    attachments: attachmentsList,
    type: ExamOrderItemEvents.NOTE_ADDED.type,
  };
  const payload = clone(omitBy(data, isNil));
  const attachments = data.attachments || [];
  const allPromise = attachments.map((attachment, index) => getPayloadAttachment(payload, attachment, index));
  await Promise.all(allPromise);

  return updateExamOrderItem(payload);
};

type GetExamOrderItemHistoriesResponse = { examOrderItemHistories: ExamOrderItemActivityModel[]; totalCount: number };
export const getExamOrderItemHistories = (tspHesiExamPymtOrderItemId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrderItems/${tspHesiExamPymtOrderItemId}/histories`) as Promise<GetExamOrderItemHistoriesResponse>;

export const addNoChargeLicenses = async (tspHesiExamPymtOrderItemId: number, examOrderAddLicenseEditor: ExamOrderItemAddLicenseEditorModel) => {
  const reasonValue = EXAM_ORDER_ADD_LICENSE_REASONS.find(reason => reason.value === examOrderAddLicenseEditor.reason);
  const data = {
    ...examOrderAddLicenseEditor,
    tspHesiExamPymtOrderItemId,
    reason: reasonValue ? reasonValue.name : '',
  };
  const payload = clone(omitBy(data, isNil));
  const attachments = data.attachments || [];
  const allPromise = attachments.map((attachment, index) => getPayloadAttachment(payload, attachment, index));
  await Promise.all(allPromise);

  return tspServiceApi.post(`/hesi/alc/admin/examOrderItems/${data.tspHesiExamPymtOrderItemId}/orderNoChargeLicense`, { data: payload });
};
