import { PagingOptions } from '../../../models/api.model';
import { buildEvolvePagingOptions } from '../../../helpers/api.helpers';
import { tspServiceApi } from '../../../helpers/RestClient.helper';
import { ExamOrderModel, ExamOrderDashboardFilterParamsModel, ExamPaymentOrderModel, ExamPaymentOrderStatus } from '../models/examOrder.model';

export const getExamOrderDashboard = (pagingOptions: PagingOptions, requestParam: ExamOrderDashboardFilterParamsModel) => {
  const data = {
    ...buildEvolvePagingOptions(pagingOptions),
    ...requestParam
  };
  return tspServiceApi.post('/hesi/alc/admin/examOrders/search', { data }) as Promise<{
    examOrders: ExamOrderModel[];
    totalCount: number;
    startIndex?: number;
    size?: number;
  }>;
};

export const getExamOrderDetails = (tspHesiExamPymtOrderId: number | string) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${tspHesiExamPymtOrderId}`) as Promise<ExamOrderModel>;

type GetExamOrderPaymentsByIdResponse = { examOrderId: string; examPymtOrders: ExamPaymentOrderModel[] };
export const getExamOrderPaymentsById = (tspHesiExamPymtOrderId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examOrders/${tspHesiExamPymtOrderId}/examPymtOrders`) as Promise<GetExamOrderPaymentsByIdResponse>;

export const fetchHesiInvoiceStatusHistory = (tspHesiInvoiceId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examPymtOrders/invoices/${tspHesiInvoiceId}/statusHistory`) as Promise<{
    tspHesiInvoiceId: number;
    statusHistory: ExamPaymentOrderStatus[];
  }>;

export const fetchHesiEcomPaymentStatusHistory = (tspHesiEcomPaymentId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examPymtOrders/ecomPayments/${tspHesiEcomPaymentId}/statusHistory`) as Promise<{
    tspHesiEcomPaymentId: number;
    statusHistory: ExamPaymentOrderStatus[];
  }>;

type ExportExamOrdersAPIResponse = Promise<string>;

export const exportExamOrders = (pagingOptions: PagingOptions, requestParam: ExamOrderDashboardFilterParamsModel): ExportExamOrdersAPIResponse => {
  const data = {
    ...buildEvolvePagingOptions(pagingOptions),
    ...requestParam
  };
  return tspServiceApi.post('/hesi/alc/admin/examOrders/export', { data }) as ExportExamOrdersAPIResponse;
};
